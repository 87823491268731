import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { getOpenGraphBlurb, getNumWords } from '../../utils/blog-content-helpers';
import BlogEntry from '../../containers/Blog/BlogEntry';
import Layout from '../../components/Layout';
import CompanyQuote from '../../components/CompanyQuote';

const propTypes = {
  data: PropTypes.shape({
    contentfulBlog: PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.shape({
        json: PropTypes.shape({}).isRequired,
      }),
    }).isRequired,
    companyQuote: PropTypes.shape({
      title: PropTypes.string,
      quote: PropTypes.string,
      footerDescription: PropTypes.shape({}).isRequired,
      icons: PropTypes.arrayOf({
        fluid: PropTypes.shape({}),
      }).isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

const BlogPage = ({ data, location }) => {
  const { contentfulBlog: blog, companyQuote } = data;

  const seo = {
    lang: 'en',
    description: `Tech Holding is an international full-service technology consulting agency in Los Angeles, California.`,
    openGraph: {
      image: blog.image ? `https:${blog.image.fluid.src}` : '',
      title: `${blog.title} | Tech Holding`,
      description: blog.description ? getOpenGraphBlurb(blog.description) : '',
      pathname: location.pathname,
    },
  };

  const wordCount = getNumWords(blog.description);
  return (
    <Layout
      currentPage="/blog"
      seo={seo}
      title={blog.title}
      footerDescription={companyQuote.footerDescription}
    >
      <BlogEntry {...blog} wordCount={wordCount} />
      <CompanyQuote companyQuote={companyQuote} />
    </Layout>
  );
};

BlogPage.propTypes = propTypes;
export default BlogPage;

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulBlog(slug: { eq: $slug }) {
      title
      subtitle
      slug
      updatedAt(formatString: "MMM D, YYYY")
      createdAt(formatString: "MMM D, YYYY")
      image {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      description {
        json
      }
      author {
        name
        role
        twitter
        linkedIn
        image {
          fluid(quality: 100, maxHeight: 72, maxWidth: 72) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      relatedPosts {
        title
        subtitle
        slug
        createdAt(formatString: "MMM D, YYYY")
        updatedAt(formatString: "MMM D, YYYY")
        image {
          fluid(quality: 100, maxWidth: 1000) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        description {
          json
        }
      }
    }
    companyQuote: contentfulCompanyQuote(label: { eq: "servicesAws" }) {
      title
      quote
      footerDescription {
        json
      }
      icons {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`;
