import React from 'react';
import PropTypes from 'prop-types';
import BlogList from '../BlogList';
import BlogHero from './BlogHero';
import BlogAuthor from './BlogAuthor';
import BlogArticle from './BlogArticle';
import s from './BlogEntry.module.scss';

const propTypes = {
  relatedPosts: PropTypes.array,
  wordCount: PropTypes.number,
  description: PropTypes.shape({
    json: {
      content: PropTypes.array,
    },
  }),
  author: PropTypes.shape({}),
  updatedAt: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  image: PropTypes.shape({}),
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

const defaultProps = {
  relatedPosts: null,
  wordCount: 1,
  description: {
    json: {
      content: [],
    },
  },
  author: {},
  image: null,
  subtitle: '',
};

const BlogEntry = ({
  author,
  updatedAt,
  createdAt,
  wordCount,
  description,
  relatedPosts,
  image,
  title,
  subtitle,
}) => (
  <>
    <div className={s.blogHead}>
      <div className={s.container}>
        <h1>{title}</h1>
        <div className={s.authorContainer}>
          {author && (
            <div className={s.author} data-aos="fade-up">
              <BlogAuthor
                wordCount={wordCount}
                publishedDate={createdAt}
                updatedDate={updatedAt}
                {...author}
              />
            </div>
          )}
        </div>
      </div>
    </div>
    {image && (
      <div className={s.hero}>
        <BlogHero image={image} />
      </div>
    )}
    <div className={s.blogBody}>
      <div className={s.container}>
        <div className={s.article} data-aos="fade-up" data-aos-delay="400">
          <BlogArticle description={description} title={title} subtitle={subtitle} />
        </div>
        {/* {author && (
          <div className={s.author} data-aos="fade-up">
            <BlogAuthor
              wordCount={wordCount}
              publishedDate={createdAt}
              updatedDate={updatedAt}
              {...author}
            />
          </div>
        )} */}
      </div>
    </div>
    {relatedPosts && (
      <div className={s.moreBlogs}>
        <div className={s.container}>
          {relatedPosts && <h2>More blogs</h2>}
          <BlogList className={s.moreBlogs} blogEntries={relatedPosts} />
        </div>
      </div>
    )}
  </>
);

BlogEntry.propTypes = propTypes;
BlogEntry.defaultProps = defaultProps;
export default BlogEntry;
