import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import MarkdownIt from 'markdown-it';
import Prism from 'prismjs';

const md = new MarkdownIt({
  html: true,
  linkify: false,
});

const propTypes = {
  markdown: PropTypes.string,
};

const defaultProps = {
  markdown: '',
};

const BlogMarkdown = ({ markdown }) => {
  useEffect(() => {
    Prism.highlightAll();
  });

  return (
    // eslint-disable-next-line react/no-danger
    <div dangerouslySetInnerHTML={{ __html: md.render(markdown) }} />
  );
};

BlogMarkdown.propTypes = propTypes;
BlogMarkdown.defaultProps = defaultProps;
export default BlogMarkdown;
