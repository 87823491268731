import React from 'react';
import PropTypes from 'prop-types';
import s from './PullQuote.module.scss';

const propTypes = {
  quote: PropTypes.string.isRequired,
  author: PropTypes.string,
};

const defaultProps = {
  author: null,
};

const BlogArticle = ({ quote, author }) => {
  return (
    <blockquote className={s.quoteContainer}>
      <p className={s.quote}>{quote}</p>
      {author && <footer className={s.author}>{`- ${author}`}</footer>}
    </blockquote>
  );
};

BlogArticle.propTypes = propTypes;
BlogArticle.defaultProps = defaultProps;
export default BlogArticle;
