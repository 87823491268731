import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  theme: PropTypes.string,
};

const defaultProps = {
  theme: 'light',
};

const FacebookIcon = ({ theme }) => {
  let rectFill = '#eeeeee';
  let fill = 'black';
  if (theme === 'dark') {
    rectFill = '#4D4D4D';
    fill = 'white';
  }
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="2" fill={rectFill} />
      <path
        d="M24.3476 14.962L22.6533 14.9627C21.3248 14.9627 21.0676 15.5941 21.0676 16.5206V18.5634H24.236L24.2348 21.7632H21.0676V29.9741H17.7628V21.7632H15V18.5634H17.7628V16.2039C17.7628 13.4653 19.4357 11.9741 21.8787 11.9741L24.3478 11.978L24.3476 14.962Z"
        fill={fill}
      />
    </svg>
  );
};
FacebookIcon.propTypes = propTypes;
FacebookIcon.defaultProps = defaultProps;
export default FacebookIcon;
