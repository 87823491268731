import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  theme: PropTypes.string,
};

const defaultProps = {
  theme: 'light',
};

const GithubIcon = ({ theme }) => {
  let rectFill = '#eeeeee';
  let fill = 'black';
  if (theme === 'dark') {
    rectFill = '#4D4D4D';
    fill = 'white';
  }
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="2" fill={rectFill} />
      <path
        d="M20 10.2969C14.475 10.2969 10 14.7744 10 20.2969C10 24.716 12.865 28.4635 16.8375 29.7844C17.3375 29.8785 17.5208 29.5694 17.5208 29.3035C17.5208 29.066 17.5125 28.4369 17.5083 27.6035C14.7267 28.2069 14.14 26.2619 14.14 26.2619C13.685 25.1077 13.0275 24.7994 13.0275 24.7994C12.1217 24.1794 13.0975 24.1919 13.0975 24.1919C14.1017 24.2619 14.6292 25.2219 14.6292 25.2219C15.5208 26.751 16.97 26.3094 17.5417 26.0535C17.6317 25.4069 17.8892 24.966 18.175 24.716C15.9542 24.466 13.62 23.606 13.62 19.7744C13.62 18.6827 14.0075 17.791 14.6492 17.091C14.5367 16.8385 14.1992 15.8219 14.7367 14.4444C14.7367 14.4444 15.5742 14.176 17.4867 15.4694C18.2867 15.2469 19.1367 15.1369 19.9867 15.1319C20.8367 15.1369 21.6867 15.2469 22.4867 15.4694C24.3867 14.176 25.2242 14.4444 25.2242 14.4444C25.7617 15.8219 25.4242 16.8385 25.3242 17.091C25.9617 17.791 26.3492 18.6827 26.3492 19.7744C26.3492 23.616 24.0117 24.4619 21.7867 24.7077C22.1367 25.0077 22.4617 25.621 22.4617 26.5577C22.4617 27.896 22.4492 28.971 22.4492 29.296C22.4492 29.5585 22.6242 29.871 23.1367 29.771C27.1375 28.4594 30 24.7094 30 20.2969C30 14.7744 25.5225 10.2969 20 10.2969Z"
        fill={fill}
      />
    </svg>
  );
};
GithubIcon.propTypes = propTypes;
GithubIcon.defaultProps = defaultProps;
export default GithubIcon;
