import React from 'react';
import PropTypes from 'prop-types';
import FacebookIcon from './FacebookIcon';
import GithubIcon from './GithubIcon';
import TwitterIcon from './TwitterIcon';
import LinkedinIcon from './LinkedinIcon';
import s from './SocialMediaLinks.module.scss';

const propTypes = {
  facebook: PropTypes.string,
  twitter: PropTypes.string,
  linkedIn: PropTypes.string,
  github: PropTypes.string,
  name: PropTypes.string,
  theme: PropTypes.string,
};

const defaultProps = {
  facebook: '',
  twitter: '',
  linkedIn: '',
  github: '',
  name: '',
  theme: 'light',
};

const SocialMediaLinks = ({ facebook, twitter, linkedIn, github, name, theme }) => {
  return (
    <div className={s.socialMediaContainer} data-aos="fade-up" data-aos-delay="350">
      {facebook && (
        <a
          href={`https://www.facebook.com/${facebook}`}
          className={s.socialMedia}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={`Check out ${name} on Facebook`}
        >
          <FacebookIcon theme={theme} />
        </a>
      )}
      {twitter && (
        <a
          href={`https://twitter.com/${twitter}`}
          className={s.socialMedia}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={`Check out ${name} on Twitter`}
        >
          <TwitterIcon theme={theme} />
        </a>
      )}
      {linkedIn && (
        <a
          href={`https://www.linkedin.com/in/${linkedIn}`}
          className={s.socialMedia}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={`Check out ${name} on LinkedIn`}
        >
          <LinkedinIcon theme={theme} />
        </a>
      )}
      {github && (
        <a
          href={`https://github.com/${github}`}
          className={s.socialMedia}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={`Check out ${name} on LinkedIn`}
        >
          <GithubIcon theme={theme} />
        </a>
      )}
    </div>
  );
};
SocialMediaLinks.propTypes = propTypes;
SocialMediaLinks.defaultProps = defaultProps;
export default SocialMediaLinks;
