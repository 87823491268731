import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    details: PropTypes.shape({
      image: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
      }),
    }),
  }).isRequired,
};

const BlogContentImage = ({ image }) => {
  const width = image?.details?.image?.width;
  const height = image?.details?.image?.height;
  const verticalImage = width / height <= 1;
  const imageStyle = {
    width: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
  };
  return (
    <picture>
      <source
        srcSet={`${image.url}?w=250&q=100 250w,${image.url}?w=500q=100 500w, ${image.url}?w=1000&q=100 1000w, ${image.url}?w=1500&q=100 1500w, ${image.url}?w=2000&q=100 2000w, ${image.url}?w=2320&q=100 2320w`}
        sizes="(max-width: 1000px) 100vw, 1000px"
      />
      <img
        sizes="(max-width: 1000px) 100vw, 1000px"
        srcSet={`${image.url}?w=250&q=100 250w, ${image.url}?w=500q=100 500w, ${image.url}?w=1000&q=100 1000w, ${image.url}?w=1500&q=100 1500w, ${image.url}?w=2000&q=100 2000w, ${image.url}?w=2320&q=100 2320w`}
        src={`${image.url}?w=1000&q=100`}
        alt="test"
        loading="lazy"
        style={verticalImage ? imageStyle : {}}
      />
    </picture>
  );
};

BlogContentImage.propTypes = propTypes;
export default BlogContentImage;
