import React from 'react';
import PropTypes from 'prop-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES } from '@contentful/rich-text-types';

import PullQuote from './PullQuote';
import BlogMarkdown from './BlogMarkdown';
import BlogContentImage from './BlogContentImage';
import s from './BlogArticle.module.scss';

const propTypes = {
  description: PropTypes.shape({
    json: PropTypes.shape({
      content: PropTypes.array,
    }).isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

const defaultProps = {
  subtitle: '',
};

const renderContent = (content) => {
  if (content.nodeType === 'embedded-entry-block' && content.data?.target?.fields?.quoteText) {
    const author =
      content.data?.target?.fields?.author && content.data?.target?.fields?.author['en-US']
        ? content.data.target.fields.author['en-US']
        : null;
    return <PullQuote quote={content.data.target.fields.quoteText['en-US']} author={author} />;
  }

  if (content.nodeType === 'embedded-entry-block' && content?.data?.target?.fields?.markdownText) {
    return <BlogMarkdown markdown={content.data.target.fields.markdownText['en-US']} />;
  }

  if (content.nodeType === 'embedded-asset-block' && content?.data?.target?.fields?.file) {
    return <BlogContentImage image={content.data.target.fields.file['en-US']} />;
  }

  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (node) => {
        return (
          <a target="_blank" rel="noopener noreferrer" href={node.data.uri}>
            {node.content[0].value}
          </a>
        );
      },
    },
  };

  return documentToReactComponents(content, options);
};

const BlogArticle = ({ description, title, subtitle }) => (
  <div className={s.articleContainer}>
    <h2>{title}</h2>
    <p className={s.subtitle}>{subtitle}</p>
    {description?.json?.content.map(renderContent)}
  </div>
);

BlogArticle.propTypes = propTypes;
BlogArticle.defaultProps = defaultProps;
export default BlogArticle;
