import React from 'react';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';
import classnames from 'classnames';

import SocialMediaLinks from '../../../../components/SocialMediaLinks';
import useScrollDirection from '../../../../utils/hooks/useScrollDirection';

import s from './BlogAuthor.module.scss';

const propTypes = {
  name: PropTypes.string.isRequired,
  publishedDate: PropTypes.string.isRequired,
  // updatedDate: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  facebook: PropTypes.string,
  github: PropTypes.string,
  twitter: PropTypes.string,
  linkedIn: PropTypes.string,
  image: PropTypes.shape({
    fluid: PropTypes.shape({}),
  }),
  wordCount: PropTypes.number.isRequired,
};

const defaultProps = {
  facebook: '',
  twitter: '',
  linkedIn: '',
  github: '',
  image: {},
};

const BlogAuthor = ({
  publishedDate,
  // updatedDate,
  facebook,
  linkedIn,
  github,
  name,
  role,
  twitter,
  image,
  wordCount,
}) => {
  const { scrollDirection } = useScrollDirection();

  return (
    <div
      className={classnames(s.container, {
        [s.headerShown]: scrollDirection === 'up',
      })}
    >
      <p className={s.publishedDate}>
        Posted on&nbsp;
        {/* {publishedDate} • */}
        {`${publishedDate} • `}
        <span className={s.wordCount}>{`${Math.ceil(wordCount / 200)} min read`}</span>
        {/* <span className={s.createdTime}>{`${createdAt} • `}</span> */}
        {/* {updatedDate === publishedDate && (
          <>
            <span className={s.wordCount}>{`${Math.ceil(wordCount / 200)} min read`}</span>
          </>
        )} */}
      </p>
      {/* {updatedDate !== publishedDate && (
        <p className={s.publishedDate}>
          Updated on&nbsp;
          {updatedDate}
          <span className={s.wordCount}>{`${Math.ceil(wordCount / 200)} min read`}</span>
        </p>
      )} */}
      <div className={s.authorContainer}>
        <div className={s.authorDetails}>
          {image && <Image fluid={image.fluid} className={s.authorImage} />}
          <div className={s.authorInfo}>
            <h4 className={s.authorName}>{name}</h4>
            <p className={s.authorRole}>{role}</p>
          </div>
        </div>

        <div className={s.socialMediaContainer}>
          <SocialMediaLinks
            facebook={facebook}
            twitter={twitter}
            linkedIn={linkedIn}
            github={github}
            theme="light"
          />
        </div>
      </div>
    </div>
  );
};

BlogAuthor.propTypes = propTypes;
BlogAuthor.defaultProps = defaultProps;
export default BlogAuthor;
