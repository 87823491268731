import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  theme: PropTypes.string,
};

const defaultProps = {
  theme: 'light',
};

const LinkedinIcon = ({ theme }) => {
  let rectFill = '#eeeeee';
  let fill = 'black';
  if (theme === 'dark') {
    rectFill = '#4D4D4D';
    fill = 'white';
  }
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="2" fill={rectFill} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1635 10C11.9679 10 11.0002 10.9695 11 12.1623C11 13.3563 11.9677 14.3256 13.1637 14.3256C14.3563 14.3256 15.3254 13.3563 15.3254 12.1623C15.3254 10.9693 14.3561 10 13.1635 10ZM15.0285 15.964H11.2979V27.9669H15.0285V15.964ZM20.9958 17.6041C21.4939 16.6605 22.7107 15.6656 24.5254 15.6656C28.3031 15.6656 29.0004 18.1515 29.0004 21.3831V27.9665H25.2725V22.1293C25.2725 20.7376 25.2469 18.9472 23.3339 18.9472C21.3931 18.9472 21.095 20.4635 21.095 22.0289V27.9667H17.3677V15.964H17.3679H20.9459V17.6041H20.9958Z"
        fill={fill}
      />
    </svg>
  );
};

LinkedinIcon.propTypes = propTypes;
LinkedinIcon.defaultProps = defaultProps;
export default LinkedinIcon;
