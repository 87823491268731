import React from 'react';
import PropTypes from 'prop-types';
import ImageWithPortraitCheck from '../../../../components/ImageWithPortraitCheck';
import s from './BlogHero.module.scss';

const propTypes = {
  image: PropTypes.shape({
    fluid: PropTypes.shape({
      aspectRatio: PropTypes.number,
    }),
  }).isRequired,
};

const BlogHero = ({ image }) => (
  <ImageWithPortraitCheck image={image} className={s.heroContainer} />
);

BlogHero.propTypes = propTypes;
export default BlogHero;
