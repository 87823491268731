import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  theme: PropTypes.string,
};

const defaultProps = {
  theme: 'light',
};

const TwitterIcon = ({ theme }) => {
  let rectFill = '#eeeeee';
  let fill = 'black';
  if (theme === 'dark') {
    rectFill = '#4D4D4D';
    fill = 'white';
  }
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="2" fill={rectFill} />
      <path
        d="M28.979 14.7464C28.9532 14.716 28.9106 14.7057 28.8743 14.7223C28.3361 14.961 27.77 15.1326 27.1869 15.234C27.8059 14.7683 28.2672 14.1235 28.5037 13.384C28.5151 13.3486 28.5033 13.3099 28.4744 13.2869C28.4453 13.2638 28.4049 13.2609 28.373 13.2799C27.6758 13.6934 26.9206 13.9848 26.1275 14.1465C25.4248 13.4172 24.4435 13 23.4278 13C21.3623 13 19.6821 14.6802 19.6821 16.7456C19.6821 16.9954 19.7063 17.2428 19.7542 17.483C16.8934 17.3064 14.2184 15.9329 12.3995 13.702C12.3809 13.6792 12.3527 13.6664 12.3232 13.6694C12.2939 13.6716 12.2677 13.6882 12.2529 13.7135C11.9211 14.2827 11.7458 14.9341 11.7458 15.5966C11.7458 16.745 12.2675 17.8187 13.1559 18.5272C12.6985 18.4722 12.2518 18.3282 11.849 18.1046C11.8219 18.0893 11.7882 18.0895 11.7611 18.1052C11.734 18.1207 11.7168 18.1494 11.7161 18.1808L11.7157 18.2287C11.7157 19.8779 12.8025 21.3261 14.3487 21.8069C13.9415 21.8725 13.5189 21.8681 13.1036 21.7888C13.073 21.7831 13.0409 21.7936 13.0199 21.8172C12.999 21.8408 12.992 21.8738 13.0016 21.9036C13.4653 23.3508 14.7535 24.3657 16.2531 24.4922C15.0022 25.4086 13.5237 25.8914 11.9615 25.8914C11.6737 25.8914 11.3839 25.8743 11.1 25.8409C11.0594 25.8363 11.0192 25.8605 11.0052 25.8994C10.9912 25.9386 11.0061 25.9825 11.0412 26.0048C12.7317 27.089 14.6864 27.6618 16.6943 27.6618C23.2619 27.6618 27.1865 22.3269 27.1865 17.1694C27.1865 17.0258 27.1837 16.8829 27.178 16.7404C27.8865 16.2222 28.4947 15.5878 28.9851 14.8535C29.007 14.8207 29.0046 14.777 28.979 14.7464Z"
        fill={fill}
      />
    </svg>
  );
};

TwitterIcon.propTypes = propTypes;
TwitterIcon.defaultProps = defaultProps;
export default TwitterIcon;
